
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonRow,
  IonCol,
  IonText,
  IonMenuButton,
  IonButton,
  IonIcon,
  IonRefresher,
  IonRefresherContent,
  loadingController,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonToggle,
  IonLabel,
} from "@ionic/vue";
import { computed, defineComponent, onMounted, onUnmounted, Ref, ref } from "vue";
import { useRouter } from "vue-router";
import { checkmarkOutline, alertOutline, logInOutline, saveOutline, trashOutline } from "ionicons/icons";
import { useStore, ActionTypes } from "../store";
import { useErrorBox } from "@/components/errorBox";
import { EventVisitDetailedInfo, EventVisitsDetailedInfo, NewVisitViewModel, SeasonTicketDetailedInfo } from "@/models/NewVisitModel";
import * as moment from 'moment-timezone';
import { ReservationViewModel } from "@/models/ReservationViewModel";
import { createGuid } from "@/common/utils";
import { EventInstanceUpdated, EventInstanceViewModel, PublicProfileViewModel } from "@/models/EventInstanceViewModel";
import { emitter } from "@/components/useEvents";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UnsubscribeFunction } from "emittery";

export default defineComponent({
  name: "PartisipantsView",
  data() {
    return {
      processing: false,
    };
  },
  components: {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonRow,
    IonCol,
    IonText,
    IonMenuButton,
    IonButton,
    IonIcon,
    IonRefresher,
    IonRefresherContent,
    IonInput,
    IonSelect,
    IonSelectOption,
    IonToggle,
    IonLabel,
},
  setup() {
    const router = useRouter();
    const store = useStore();
    const {showError} = useErrorBox();
    const momentjs: any = moment;
    moment.locale("fi");

    const editor = ClassicEditor;
    const editorConfig = {};

    const visitsInfo: Ref<EventVisitsDetailedInfo|undefined> = ref(undefined);
    const eventInfo: Ref<EventInstanceViewModel|undefined> = ref(undefined);
    const publicProfiles: Ref<Array<PublicProfileViewModel>|undefined> = ref(undefined);

    const editorVisible: Ref<boolean> = ref(false);


    const hasRoleInstructor = computed(() => { 
      return store.getters.userInfo.roles?.indexOf("Instructor") >= 0 ||
             store.getters.userInfo.roles?.indexOf("Admin") >= 0;});
    
    const hasRoleMerchant = computed(() => { 
      return store.getters.userInfo.roles?.indexOf("Merchant") >= 0 ||
             store.getters.userInfo.roles?.indexOf("Admin") >= 0;});

    const loading = async () => {
      const loading = await loadingController
          .create({
            cssClass: 'my-custom-class',
            message: 'Hetki...',
            duration: undefined,
          });

      await loading.present();
      return loading;
    }    

    const loadPartisipants = async () => {
      visitsInfo.value = undefined;
      const l = await loading();
      try
      {
        const id = router.currentRoute.value.params["id"].toString();
        const result = await store.dispatch(ActionTypes.GET_EVENT_PARTISIPANTS, id);
        visitsInfo.value = result;

        const publicProfilesResult = await store.dispatch(ActionTypes.GET_PUBLIC_PROFILES, undefined);
        console.log(publicProfilesResult);
        publicProfiles.value = publicProfilesResult;        

        const eventInstance = await store.dispatch(ActionTypes.GET_EVENT_INSTANCE, id);
        console.log(eventInstance);
        eventInfo.value = eventInstance;
      }
      catch(err){
        showError(err as string,true);
      }
      finally{
        l.dismiss();
      }

    }

    const visibilityChanged = async () => {
      if (document.visibilityState === 'hidden') {
        console.log("hidden");
      } else {
        await loadPartisipants();
      }
    };

    let emitterDismiss: UnsubscribeFunction|undefined = undefined;

    onMounted(async () => {
      await loadPartisipants();
      document.addEventListener("visibilitychange",visibilityChanged);
      emitterDismiss = emitter.on('eventInstanceUpdated',async (data: EventInstanceUpdated) => {
        if(!data || !visitsInfo.value || !data.id || data.id != visitsInfo.value.id)
          return;        
        await loadPartisipants();
      });
    });

    onUnmounted(async ()=>{
      document.removeEventListener("visibilitychange",visibilityChanged);
      if(emitterDismiss)
        emitterDismiss();
    });

    const unRegisterEvent = (reservation: ReservationViewModel) => {
      if(!reservation.id)
        return;

      store.dispatch(ActionTypes.REMOVE_RESERVATION,reservation.id)
        .then(success => {
          if(!success || !visitsInfo.value )
            return;
          visitsInfo.value.reservations = visitsInfo.value.reservations.filter(i=>i.id != reservation.id);
      }).catch(r=>{ showError(r,true);});
    };

    const removeSigninFromEvent = (visit: EventVisitDetailedInfo) => {
      
      if(!visit.id)
        return;

      store.dispatch(ActionTypes.REMOVE_VISIT,visit.id)
        .then(success => {
        if(!success || !visitsInfo.value )
            return;
          visitsInfo.value.reservations = visitsInfo.value.reservations.filter(i=>i.id != visit.id);
      }).catch(r=>{ showError(r,true);});
    };


    const signInToEvent = (sticket: SeasonTicketDetailedInfo) => {
      if(!sticket.ticketId) {
        showError("asiakkaan korttia ei löytynyt",false);
        return; 
      }

      if(!visitsInfo.value) {
        showError("osallistujatietoja ei löytynyt",false);
        return; 
      }

          const vm = new NewVisitViewModel();
          vm.eventId= visitsInfo.value.id;
          vm.id= createGuid();
          vm.memberId= sticket.familyMemberId;
          vm.quantity= 1;
          vm.ticketId= sticket.ticketId;

      store.dispatch(ActionTypes.ADD_VISIT,vm)
      .then(() => { showError("lisättiin tunnille",false); })
      .catch(r=>{ showError(r,true);});
    };

     const isSigned = (sticket: SeasonTicketDetailedInfo) => {

      if( !sticket.ticketId || !visitsInfo.value)
        return false;
      const v = visitsInfo.value.visits.find(i=>i.ticketId == sticket.ticketId);
      return v !== undefined;
     }

      const ticketColor = (sticket: SeasonTicketDetailedInfo) => {
        if(!sticket)
          return "error";

        if(!sticket.ticketActive)
          return "warning";

        return "primary";
     }

    const refreshNow = (item: any) => {
      loadPartisipants();
      item.target.complete();
    };

    const save = async () => {
      if(!eventInfo.value)
        return false;

      const l = await loading();

      try {
        const r = await store.dispatch(ActionTypes.UPDATE_EVENT_INSTANCE, eventInfo.value);        
        if(r?.id) { eventInfo.value = r; }
         l.dismiss();
        return true;
      } catch(err) {
        const a = await showError(err as string,true);
        l.dismiss();
        await a.onDidDismiss();        
        return false;
      }
    };

    const checkSelectedPublicProfile = (tag1: PublicProfileViewModel, tag2: PublicProfileViewModel) => {	
      // check if the secondary tag is an array of multiple
			if(Array.isArray(tag2))
				return (tag2 as Array<PublicProfileViewModel>).findIndex(i=>tag1.publicProfileId === i.publicProfileId)>-1;

      // compare individual objects
			return tag1.publicProfileId===tag2.publicProfileId;
		};

    return {
      visitsInfo,eventInfo,
      momentjs,
      hasRoleInstructor,
      hasRoleMerchant,
      unRegisterEvent,
      signInToEvent,
      isSigned,
      checkmarkOutline,saveOutline,trashOutline,
      alertOutline,
      logInOutline,
      ticketColor,
      refreshNow,save,
      checkSelectedPublicProfile,
      publicProfiles,editor,editorConfig,
      editorVisible,
      removeSigninFromEvent,
    };
  }
});
