
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
  IonButton,
  IonMenuButton,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonInput,
  IonCheckbox,
} from "@ionic/vue";
import { computed, defineComponent, onMounted, Ref, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore, ActionTypes } from "../store";
import { ProductViewModel } from "@/models/ProductViewModel";
import { EventCategoryViewModel } from "@/models/EventCategoryViewModel";
import { useErrorBox } from "@/components/errorBox";
import { OrganizationInfo } from "@/models/Organization";
import { useCalendarStore } from "@/store/calendarStore";

export default defineComponent({
  name: "ListProducts",
  data() {
    return {
      searchTerm: "",
      processing: false,
    };
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonItem,
    IonButton,
    IonMenuButton,
    IonGrid,
    IonRow,
    IonCol,
    IonText,
    IonInput,
    IonCheckbox,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const calendarStore = useCalendarStore();
    const {showError} = useErrorBox();

    const allProducts: Ref<Array<ProductViewModel>> = ref([]);
    const allCategories: Ref<Array<EventCategoryViewModel>> = ref([]);
    const allOrganizations: Ref<Array<OrganizationInfo>> = ref([]);

    const showAll: Ref<boolean> = ref(false);
    const filterTxt: Ref<string|undefined> = ref(undefined);

    onMounted(() => {
      store.dispatch(ActionTypes.GET_PRODUCTS, undefined)
      .then((result) => {
        if(result){
          allProducts.value = result.items;
          }
      })
      .catch((_err) => {
        router.replace("/login");
      });

      calendarStore.getCategories(store.state.organization.id)
      .then((result) => {
        if(result){
          allCategories.value = result;
        }
      })
      .catch((err) => {
        console.log(err);
        showError(err.message, true);
      });

      store.dispatch(ActionTypes.GET_ORGANIZATIONS, undefined)
      .then((result) => {
        if(result){
          allOrganizations.value = result;
        }
      })
      .catch((err) => {
        console.log(err);
        showError(err.message, true);
      });
 });

     const filteredProducts = computed(() => {
       const lowerSearch = filterTxt.value?.toLowerCase();  
       return allProducts.value.filter(s => (showAll.value || s.isActive) && (
         !lowerSearch || lowerSearch.length == 0 || 
         s.name?.toLowerCase().indexOf(lowerSearch) >= 0 || 
         s.barcode?.toLowerCase().indexOf(lowerSearch) >= 0 || 
         s.code?.toLowerCase().indexOf(lowerSearch) >= 0))
        .sort((a: any, b: any) => a.orderKey - b.orderKey);
    });
   
    const editProduct = (item: ProductViewModel) => {
        if(item){
            router.push(`/editProduct/${item.id}`);
      }
    };

    const getCategory = (id: string) => {
        if(id){
          return allCategories.value.find(i=>i.id===id)?.title;
      }
    };

        const getOrganization = (id: string) => {
        if(id){
          return allOrganizations.value.find(i=>i.id===id)?.name;
      }
    };




    return {
      router,
      editProduct,
      filteredProducts,
      getCategory,
      getOrganization,
      allProducts,
      filterTxt,
      showAll,
    };
  },
});
