
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonMenuButton,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
} from "@ionic/vue";
import { defineComponent, onMounted, Ref, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore, ActionTypes, getDomain } from "@/store";
import { useErrorBox } from "@/components/errorBox";
import { SqlRequestModel } from "@/models/SqlRequestModel";
import axios from "axios";

export default defineComponent({
  name: "ListSqlRequests",
  components: {
     IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
  // IonList,
  // IonItem,
  // IonLabel,
  // IonButton,
  IonMenuButton,
    IonGrid,
  IonRow,
  IonCol,
  IonText,
  //  IonInput,
  //  IonCheckbox,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const {showError} = useErrorBox();

    const allSqlRequests: Ref<Array<SqlRequestModel>> = ref([]);


    onMounted(() => {
      store.dispatch(ActionTypes.LIST_SQL_REQUESTS, undefined)
      .then((result) => {
        if(result){
          allSqlRequests.value = result;
          }
      })
      .catch((err) => {
        showError(err,true);
      });
    });

 const downloadFile = (r: SqlRequestModel) => {
              axios({
                    url: getDomain() + '/SqlRequest/Run?id=' + r.id, // File URL Goes Here
                    method: 'GET',
                    responseType: 'arraybuffer', //'arraybuffer'
                    headers: {Authorization: 'Bearer ' + store.state.user.authToken},
                }).then((res) => {

                  // debugger;
                     const FILE = window.URL.createObjectURL(new Blob([res.data]));
                     
                     const docUrl = document.createElement('x');
                     (docUrl as any).href = FILE;
                     docUrl.setAttribute('download', 'file.xlsx');
                     document.body.appendChild(docUrl);
                     docUrl.click();
                     document.body.removeChild(docUrl);
                });
          }

    return {
      router,
      allSqlRequests,
      downloadFile,
    };
  },
});
